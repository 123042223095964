import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import './App.css'
import Header from './components/Header'
import Homepage from './views/Homepage'
import Search from './views/Search'
import Bookmarks from './views/Bookmarks'
import NotFound from './views/NotFound'
import { FaveProvider } from './providers/FaveContext'
import { MarvelProvider } from './providers/MarvelAPI'

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Header />
        <main>
          <Switch>
            <Route path="/search" component={Search} />
            <Route path="/bookmarks" component={Bookmarks} />
            <Route path="/" component={Homepage} />

            {/*<Route path="/about" component={About} />
            
            <Route
              path="/search/:characterId"
              render={(routeProps) => {
                const { match } = routeProps
                return <CharacterDetails match={match} />
              }}
            />*/}
            <Redirect to="/" />
            <Route component={NotFound} />
          </Switch>
        </main>
      </div>
    )
  }
}

export default () => (
  <MarvelProvider>
    <FaveProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </FaveProvider>
  </MarvelProvider>
)
