import React from 'react'
import { FaveConsumer } from '../providers/FaveContext'
import Character from '../components/Character'
import { MarvelConsumer } from '../providers/MarvelAPI'

function Bookmarks() {
  return (
    <FaveConsumer>
      {(bookmarkProps) => {
        const bookmarkheading = bookmarkProps.bookmarks.length > 0 ? 'Your Faves' : 'No Favorites yet!'
        return (
          <div className="resultsList">
            <h2>{bookmarkheading}</h2>
            {bookmarkProps.bookmarks.map((characterId) => {
              return <Character key={characterId} characterId={characterId} size={`detail`} />
            })}
          </div>
        )
      }}
    </FaveConsumer>
  )
}

export default Bookmarks
