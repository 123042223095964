import React from 'react'
import Character from '../components/Character'
import { MarvelConsumer } from '../providers/MarvelAPI'
import PaginationControls from '../components/PaginationControls'
import './Homepage.css'

class Homepage extends React.Component {
  state = {
    searchResults: [],
    searchTerm: '',
    loading: false,
    error: undefined,
    page: 1,
    limit: 10,
    offset: 0,
  }

  search = (paginate) => {
    const { limit, page, offset } = this.state

    let newStatePage = page
    let newStateOffSet = offset

    if (paginate === 'next') {
      newStatePage = newStatePage + 1
      newStateOffSet = newStateOffSet + limit
    } else if (paginate === 'prev' && page !== 1) {
      newStatePage = newStatePage - 1
      newStateOffSet = newStateOffSet - limit
    } else if (paginate === 'prev' && page === 1) {
      console.error('Error: Previous link clicked on page 1 - This should never run')
      alert('Sorry you are back to the start now! ... No prev.')
      return false
    }

    // SetState initiates render of "Loading"
    this.setState({
      loading: true,
      page: newStatePage,
      offset: newStateOffSet,
    })
    console.log(
      'Plain old search.... Passing paginate=' +
        paginate +
        ' searchCharacters the current state page: ' +
        this.state.page +
        ' and newPage=' +
        newStatePage,
    )
    //this.fetchSearch(newStatePage, newStateOffSet, this.state.limit)
    //this.fetchSearch(this.state.page, this.state.offset, this.state.limit)
  }

  fetchSearch(page, offset, limit) {
    // SetState here Initiates render of "Results"
    this.props
      .searchCharacters(page, offset, limit)
      .then((data) => {
        if (data.message) {
          this.setState({
            loading: false,
            error: data.message,
          })
        } else if (data.data.count === 0) {
          this.setState({
            loading: false,
            error: 'No Results',
          })
        } else {
          this.setState({
            loading: false,
            searchResults: data.data.results,
            error: null,
          })
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: error.message,
        })
      })
  }

  componentDidMount() {
    //this.search('load')
    let { limit, page, offset } = this.state
    this.fetchSearch(page, offset, limit)
    console.log('did mount!')
  }
  componentDidUpdate(prevProps, prevState) {
    // ON render if the page changed, refetch the results
    if (prevState.page !== this.state.page) {
      console.log('component update check: ' + (prevState.page !== this.state.page))
      console.log('this.state.page=' + this.state.page)
      console.log('prevState.page=' + prevState.page)
      let { limit, page, offset } = this.state
      this.fetchSearch(page, offset, limit)
    }
  }

  shouldComponentUpdate(nextProps) {
    console.log(nextProps)
    const differentPage = true
    //const differentPage = this.props.page !== nextProps.page
    return differentPage
  }

  render() {
    const { error, searchResults, loading, limit, page, offset } = this.state
    const endRange = limit * page
    console.log('rendercount')
    return (
      <div className="resultsList">
        <h2>Marvel Characters A-Z</h2>
        <h3>
          Showing Results {this.state.offset} - {endRange}
        </h3>
        <h4>Page {this.state.page}</h4>
        <PaginationControls search={this.search} pagePrev={page - 1} />
        <div className="homepageCharList">
          {loading
            ? 'Loading...'
            : error
            ? error
            : searchResults.map((character) => {
                return <Character key={character.id} characterId={character.id} size="small" />
              })}
        </div>
        <PaginationControls pagePrev={this.state.page - 1} />
      </div>
    )
  }
}

function Wrapper() {
  return <MarvelConsumer>{(marvelProps) => <Homepage {...marvelProps} />}</MarvelConsumer>
}
export default Wrapper
