import React from 'react'
import Character from '../components/Character'
import { MarvelConsumer } from '../providers/MarvelAPI'
import './Search.css'

class SearchResults extends React.Component {
  state = {
    searchResults: [],
    searchTerm: '',
    loading: false,
    error: undefined,
  }

  handleChange = (e) => {
    this.setState(
      {
        searchTerm: e.target.value,
      },
      () => {
        clearTimeout(this.timeoutId)
        this.timeoutId = setTimeout(this.search, 600)
      },
    )
  }

  search = () => {
    this.setState({ loading: true })

    const { searchTerm } = this.state

    this.props
      .searchCharacterByName(searchTerm)
      .then((data) => {
        if (data.message) {
          this.setState({
            loading: false,
            error: data.message,
          })
        } else if (data.data.count === 0) {
          this.setState({
            loading: false,
            error: 'No Results',
          })
        } else {
          console.log(' searchCharacterByName data.data')
          console.log(data.data)
          this.setState({
            loading: false,
            searchResults: data.data.results,
            error: null,
          })
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: error.message,
        })
      })
  }

  render() {
    const { error, searchResults, searchTerm, loading } = this.state

    return (
      <div className="resultsList">
        <h2>Search Character By Name</h2>
        <p>
          The marvel API allows you to search by full name. Try searching for "Storm", "Wolverine", or "Hulk". In the future I
          would like to use the startswith filter and allow type ahead using front end filtering to find a character.
        </p>
        <input className="search-input" type="text" onChange={this.handleChange} value={searchTerm} />

        <br />
        {loading
          ? 'Loading...'
          : error
          ? error
          : searchResults.map((character) => {
              return <Character key={character.id} characterId={character.id} size={`detail`} />
            })}
      </div>
    )
  }
}

function Wrapper() {
  return <MarvelConsumer>{(marvelProps) => <SearchResults {...marvelProps} />}</MarvelConsumer>
}
export default Wrapper
