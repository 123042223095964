import React from 'react'

function PaginationControls(props) {
  let previousAvailable = props.pagePrev == 0 ? false : true
  const handleClick = (e) => {
    e.preventDefault()
    let direction = e.target.dataset['direction']
    console.log('ok so you clicked...' + direction)
    return props.search(direction)
  }

  return (
    <ul>
      <li>
        <a href="#" data-direction="prev" onClick={handleClick} className={previousAvailable ? '' : 'grayOutPrev'}>
          Previous
        </a>
      </li>
      <li> &nbsp; | &nbsp; </li>
      <li>
        <a href="#" data-direction="next" onClick={handleClick}>
          Next
        </a>
      </li>
    </ul>
  )
}

export default PaginationControls
