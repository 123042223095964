import React from 'react'
import Comic from './Comic'

function ComicsList(props) {
  let comics = props.comics.items

  console.log(comics)

  return comics.map((comic) => {
    return <Comic name={comic.name} url={comic.resourceURI} />
  })
}

export default ComicsList
