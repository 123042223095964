import React from 'react'
import { Link } from 'react-router-dom'
import { FaveConsumer } from '../providers/FaveContext'
import { MarvelConsumer } from '../providers/MarvelAPI'
import './Character.css'
import ComicsList from './ComicsList'

class Character extends React.Component {
  state = {
    loading: true,
    characterData: {},
  }

  componentDidMount = () => {
    const { characterId, size } = this.props

    this.props
      .fetchCharacter(characterId)
      .then((data) => {
        if (data.message) {
          this.setState({
            loading: false,
            error: data.message,
            characterData: {},
          })
        } else if (data.data.count === 0) {
          this.setState({
            loading: false,
            error: 'No Results',
          })
        } else {
          this.setState({
            loading: false,
            characterData: data.data.results[0],
            error: null,
          })
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: error.message,
          characterData: {},
        })
      })
  }

  handleButtonClick = () => {
    const { toggleBookmark } = this.props
    const { characterData } = this.state
    if (characterData) toggleBookmark(characterData.id)
  }

  render() {
    const { isBookmarked, characterId } = this.props
    const bookmarked = isBookmarked(characterId)
    const { loading, characterData, error } = this.state
    const buttonLabel = bookmarked ? 'Bookmarked' : 'Add Bookmark'
    const starred = bookmarked ? '⭐️' : '☆'
    const buttonClass = bookmarked ? 'bookmarked' : 'not-bookmarked'

    if (loading || !characterData || characterData.length < 1 || error != null)
      return <div className={`character small`}>Error loading character... {error}</div>

    const { name, id, description, thumbnail } = characterData
    const imgPath = thumbnail.path + '.' + thumbnail.extension

    if (this.props.size == 'detail') {
      return (
        <div className={`character ${this.props.size}`}>
          <div className="left-col">
            <img src={imgPath} alt={`${name} Image`} />
            <div>
              <small>
                <em>ID: {id}</em>
              </small>
            </div>
          </div>
          <div className="middle-col">
            <h3>{name}</h3>
            <button className={buttonClass} onClick={this.handleButtonClick}>
              {buttonLabel}
            </button>
            <p>{description}</p>
            <div>
              <h4>Comics List</h4>
              <ComicsList comics={characterData.comics} />
            </div>
          </div>
          <div className="right-col"></div>
        </div>
      )
    } else {
      return (
        <div className={`character ${this.props.size}`}>
          <div className="hide-on-detail">
            <h3>{name}</h3>
          </div>
          <div className="left-col">
            <img src={imgPath} alt={`${name} Image`} />
            <button className={buttonClass} onClick={this.handleButtonClick}>
              {buttonLabel}
            </button>
            <div>
              <small>
                <em>ID: {id}</em>
              </small>
            </div>
          </div>
        </div>
      )
    }
  }
}

function CharacterWrapper(props) {
  return (
    <MarvelConsumer>
      {(marvelProps) => {
        if (marvelProps == null) return null
        return (
          <FaveConsumer>
            {(bookmarkProps) => {
              if (bookmarkProps == null) return null
              return <Character {...props} {...bookmarkProps} {...marvelProps} />
            }}
          </FaveConsumer>
        )
      }}
    </MarvelConsumer>
  )
}
export default CharacterWrapper
