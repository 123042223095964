import React from 'react'
import { arrayIncludes, addToArray, removeFromArray } from '../utils'

const FaveContext = React.createContext()

export const FaveConsumer = FaveContext.Consumer

export class FaveProvider extends React.Component {
  state = {
    /** Hulk, Storm, Wolverine */
    bookmarks: [],
  }

  toggleBookmark = (id) => {
    const isBookmarked = arrayIncludes(this.state.bookmarks, id)
    if (!isBookmarked) {
      const newBookmarks = addToArray(this.state.bookmarks, id)
      this.setState({
        bookmarks: newBookmarks,
      })
    } else {
      const newBookmarks = removeFromArray(this.state.bookmarks, id)
      this.setState({
        bookmarks: newBookmarks,
      })
    }
  }

  isBookmarked = (characterId) => {
    //console.log('Is bookmarked ran, and checked this charId: ' + characterId)
    return arrayIncludes(this.state.bookmarks, characterId)
  }

  render() {
    const value = {
      bookmarks: this.state.bookmarks,
      toggleBookmark: this.toggleBookmark,
      isBookmarked: this.isBookmarked,
    }
    return <FaveContext.Provider value={value}>{this.props.children}</FaveContext.Provider>
  }
}
