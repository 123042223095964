import React from 'react'

function Comic(props) {
  let name = props.name

  return (
    <p>
      <a href={props.url}>{name}</a>
    </p>
  )
}

export default Comic
