import React from 'react'

function memoize(func) {
  const cache = {}

  return function() {
    const key = JSON.stringify(arguments)

    if (cache[key]) {
      //console.log('returning cached result for arguments: ' + key)
      //return localStorage.getItem(key)
      return cache[key]
    } else {
      const value = func.apply(null, arguments)
      cache[key] = value
      localStorage.setItem(key, value)
      return value
    }
  }
}

const MarvelContext = React.createContext()

export const MarvelConsumer = MarvelContext.Consumer

export class MarvelProvider extends React.Component {
  marvelFetch = memoize((uri) => {
    console.log('Beginning fetch sequence...')
    const url = `https://gateway.marvel.com:443${uri}apikey=d9d246e26785e357dda0198efa762bc3`
    try {
      return fetch(url, {})
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          //console.log(data)
          return data
        })
        .catch((error) => {
          return error
        })
    } catch (error) {
      return error
    }
  })

  fetchCharacter = (characterId) => {
    /* GET /v1/public/characters/{characterId} */
    const uri = `/v1/public/characters/${characterId}?`
    return this.marvelFetch(uri)
  }

  searchCharacters = (page, offset, limit) => {
    /* GET /v1/public/characters?name={exact name ie. Spider Man} */

    console.log('the offset=' + offset)
    const uri = `/v1/public/characters?limit=${limit}&offset=${offset}&`
    console.log(uri)

    return this.marvelFetch(uri)
  }

  searchCharacterByName = (searchTerm) => {
    /* GET /v1/public/characters?name={exact name ie. Spider Man} */
    const uri = `/v1/public/characters?name=${searchTerm}&`
    console.log(uri)
    return this.marvelFetch(uri)
  }

  searchNameByLetter = (searchTerm) => {
    /* GET /v1/public/characters?nameStartsWith={single letter} */
    const uri = `search/repositories?nameStartsWith=${searchTerm}&limit=100&`
    console.log(uri)
    return this.marvelFetch(uri)
  }

  render() {
    const value = {
      fetchCharacter: this.fetchCharacter,
      searchCharacters: this.searchCharacters,
      searchCharacterByName: this.searchCharacterByName,
      searchNameByLetter: this.searchNameByLetter,
    }
    return <MarvelContext.Provider value={value}>{this.props.children}</MarvelContext.Provider>
  }
}
